/**
 * Global CSS Variables
 * 
 * These custom properties provide a centralized way to manage
 * common values used throughout the stylesheet. This approach
 * enhances maintainability and consistency across the project.
 */
:root {
  /* Set base font size to 16px */
  font-size: 16px;

  /* Color palette */
  --text-color: #13343B;
  --background-color: #ffffff;

  /* Typography settings */
  --font-size-xs: 0.75rem;    /* 12px */
  --font-size-sm: 0.875rem;   /* 14px */
  --font-size-base: 1rem;     /* 16px */
  --font-size-lg: 1.125rem;   /* 18px */
  --font-size-xl: 1.25rem;    /* 20px */
  --font-size-2xl: 1.5rem;    /* 24px */
  --font-size-3xl: 1.875rem;  /* 30px */
  --font-size-4xl: 2.25rem;   /* 36px */
  
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;

  /* Font weight settings */
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Spacing utilities */
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.5rem;

  /* List item spacing */
  --list-item-spacing: 0.5rem;
}

/**
 * Reset Styles
 * 
 * This section normalizes default styles across browsers,
 * ensuring a consistent starting point for our design.
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/**
 * Global Font Application
 * 
 * Applies the Inter font to all elements to ensure consistency.
 */
html, body, button, input, select, textarea {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/**
 * Body Styles
 * 
 * Sets the base font weight, smoothing, colors, and background
 * for the entire document.
 */
body {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);
}

/**
 * Typography Styles
 * 
 * Defines consistent text styles for paragraphs, list items,
 * and headings throughout the application.
 */
p {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-relaxed);
  margin-bottom: var(--spacing-medium);
}

h1 {
  font-size: var(--font-size-4xl);
  line-height: var(--line-height-tight);
}

h2 {
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-tight);
}

h3 {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-snug);
}

h4 {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-snug);
}

h5, h6 {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-normal);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-semibold);
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-medium);
}

/* Remove top margin for the first heading in a section */
:is(h1, h2, h3, h4, h5, h6):first-child {
  margin-top: 0;
}

/**
 * List Styles
 * 
 * Provides consistent styling for unordered and ordered lists,
 * including proper indentation and spacing between list items.
 */
ul, ol {
  padding-left: var(--spacing-large);
  margin-bottom: var(--spacing-medium);
}

li {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-normal);
  margin-bottom: var(--list-item-spacing);
  word-spacing: normal;
}

li + li {
  margin-top: var(--list-item-spacing);
}

/**
 * Form Element Styles
 * 
 * Ensures that form elements use the Inter font and consistent sizing.
 */
button, input, select, textarea {
  font-size: var(--font-size-base);
  line-height: var(--line-height-normal);
}

/**
 * Note: This CSS uses modern CSS features like custom properties (variables)
 * and the :is() pseudo-class for better maintainability and readability.
 * Ensure that your target browsers support these features, or consider
 * using a CSS preprocessor or postprocessor for backwards compatibility.
 */
